.titleContainer>h3 {
    font-size: 5vw;
    margin: 1.5rem 0 7px 0;
    color: var(--font-color-secondary-light);
    font-family: var(--font-style-primary);
    font-weight: 500;
}

.logoDiv h3 {
    font-size: 8vw;
    line-height: 3.2vw;
    color: var(--font-color-primary);
    font-family: var(--font-style-primary);
}

.introContainer {
    width: 90%;
    margin: 15px 0 0 0;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.introContainer h4 {
    color: var(--color-secondary);
    font-size: 4vw;
    line-height: 4.2vw;
    /* */
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin: .5rem 0 -1rem 0;
    text-transform: capitalize;

    border-bottom: 2px solid #5a6f8e70;
    /* padding: 0 26px 5px 26px; */
}

.textTwo {
    color: var(--font-color-primary);
    font-size: 4vw;
    line-height: 4.2vw;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    /* margin: -.2rem 0 1.4rem 0; */
    margin: 0.8rem 0 1.4rem 0;
    text-transform: uppercase;
}

.textContainer {
    margin: 1rem 0 0 0;
    width: 100%;
}


.contentContainer {
    width: 100%;
    padding-bottom: 10vw;
    background-image: url("../../assets/mobile/in-office/background.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.iconsContainer {
    width: 100%;
    padding: 3vw 5vw;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
    row-gap: 4vw;
}

.widthStyle {
    width: 17vw;
}

.title {
    font-family: var(--font-style-secondary);
    font-size: 2.55vw;
}

.mainContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}


.titleContainer>* {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

.divBtnWrapper {
    text-align: center;
    width: 100%;
}

.moreBtn {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: white;
    width: 90%;
    margin: 6vw auto 1.5rem auto;
    padding: 4vw 0 4vw 0;
    font-size: 4vw;
    display: block;
    border-radius: .3rem;
    text-decoration: none;
}

.moreBtn:hover {
    background-color: #215858;
    transition: 0.2s;
    color: white;


}


@media screen and (min-width:728px) {

    .moreBtn {
        font-size: 1.51vw;
        padding: 1.1vw 0;
        width: 41%;
        margin: 1vw auto 2vw auto;
        /**/
    }

    .titleContainer {
        text-align: center;
        background: none;
        position: static;
    }

    .mainContainer {
        width: 100%;
        /* aspect-ratio: 15 / 10; */
        align-items: start;
        display: block;
    }

    .contentContainer {
        width: 100%;
        padding-bottom: 0%;
        background-image: url("../../assets/desktop/in-office/background.jpg");
        background-size: cover;
    }

    .titleContainer span {
        font-size: 4vw;
        margin: .5rem 0 0 0;
        color: var(--font-color-secondary-light);
        font-family: var(--font-style-primary);
        font-weight: 500;
    }

    .titleContainer h3 {
        margin: 1.5rem 0 -26px 0;
    }

    .logoDiv {
        color: var(--font-color-primary) !important;
        margin-bottom: 2rem;
    }

    .logoDiv h3 {
        font-size: 4.5vw;
    }

    .title {
        font-size: 1.11vw;
    }

    .widthStyle {
        width: 6vw;
    }

    .iconsContainer {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 6px 42px;
    }

    .introContainer {
        display: block;
        width: 94%;
        background: rgb(255 255 255 / 77%);
    }

    .introContainer h4 {
        font-size: 1.9vw;
        line-height: 2.2vw;
        margin: -1.5vw 0 0.5vw 0;
        /**/
    }

    .textContainer {
        margin: 1.5vw 0;
        text-align: center;
    }


    .textTwo {
        color: var(--font-color-primary);
        font-size: 2vw;
        line-height: 2.2vw;
    }

    .checklistWrapper {
        max-width: 100%;
        overflow: hidden;
    }

    .divider {
        padding: 0.4rem;
        background: rgb(71, 80, 95);
        background: linear-gradient(90deg, rgba(71, 80, 95, 1) 0%, rgba(89, 109, 139, 1) 46%, rgba(71, 80, 95, 1) 100%, rgba(9, 9, 121, 0) 100%, rgba(0, 212, 255, 1) 100%);
        width: 100%;
        height: 1px;
        margin-top: 2vw;
    }
}

@media screen and (min-width:1024px) {

    .textTwo {
        line-height: 7.2vw;
    }

    .titleContainer span {
        font-size: 4.5vw;
    }

    .introContainer h4 {
        font-size: 2vw;
        margin: 15px 0 -25px 0;
        border: none;
    }

    .textContainer {
        margin: -1.5vw 0 21px 0;
    }
}


@media screen and (min-width:1600px) {

    .logoDiv h3 {
        font-size: 118px;
        line-height: 99px;
    }

    .introContainer {
        margin: 28px 0 0 0;
    }

    .introContainer h4 {
        font-size: 30px;
        margin: 0 0 -32.5px 0;
    }

    .textContainer {
        margin: 34px 0 43px 0;
    }

    .textTwo {
        font-size: 28px;
        line-height: 28px;
    }

    .titleContainer span {
        font-size: 65.5px;
    }


}