.introContainerRM {
    width: 90%;
    margin: 15px 0 0 0;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.introContainerRM h4 {
    color: var(--color-secondary);
    font-size: 4vw;
    line-height: 4.2vw;
    /* */
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin: .5rem 0 -1rem 0;
    text-transform: capitalize;

    border-bottom: 2px solid;
    padding: 0 26px 5px 26px;
}

.textContainerRM {
    margin-top: 2rem;
}

.textOneRM {
    color: var(--font-color-secondary-light);
    font-size: 3.5vw;
    font-weight: bold;
    font-style: italic;
    text-align: center;
}

.textTwoRM {
    color: var(--font-color-primary);
    font-size: 4vw;
    line-height: 4.2vw;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    /* margin: -.2rem 0 1.4rem 0; */
    /* margin: 0.8rem 0 1.4rem 0; */
    text-transform: uppercase;
}

.titleContainerRM {
    margin: 1rem 0 0 0;
    width: 100%;
}

.titleContainerRM>h3 {
    font-size: 5vw;
    margin: .5rem 0 5px 0;
    color: var(--font-color-secondary-light);
    font-family: var(--font-style-primary);
    font-weight: 500;
}

.titleContainerRM>* {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

.checklistReadMore {
    display: flex;
    align-items: center;
    padding: 2vw 0;
}

.checkReadMore {
    width: 15%;
    margin-left: 5vw;
}

.checklistReadMore span {
    color: var(--font-color-secondary);
    font-size: 3.2vw;
    font-weight: 500;
    margin: 0 8vw 0 5vw;
    text-transform: capitalize;
}


@media screen and (min-width:600px) {

    .textContainerRM {
        margin-top: 0;
    }

    .checkReadMore {
        margin-left: 2vw;
    }

    .textTwoRM,
    .introContainerRM h4 {
        font-size: 4vw;
        line-height: 4.2vw;
        margin: 1rem 0 -22px 0;
        padding: 20px;
    }

}

@media screen and (min-width:728px) {

    .titleContainerRM {
        text-align: center;
        background: none;
        position: static;
    }


    .titleContainerRM span {
        font-size: 4vw;
        margin: .5rem 0 0 0;
        color: var(--font-color-secondary-light);
        font-family: var(--font-style-primary);
        font-weight: 500;
    }


    .introContainerRM {
        display: block;
        width: 96%;
        background: rgb(255 255 255 / 77%);
    }

    .introContainerRM h4 {
        font-size: 3vw;
        line-height: 3.5vw;
        font-style: normal;
        margin: 0 -1vw 7px;
    }

    .textContainerRM {
        margin: 1.5vw 0;
        text-align: center;
    }


    .textOneRM {
        color: var(--font-color-secondary);
        font-size: 2vw;
        font-style: normal;
    }

    .textTwoRM {
        color: var(--font-color-primary);
        font-size: 3vw;
        line-height: 3.5vw;
        margin: 0 4vw;
        font-style: normal;
    }

    .checklistWrapperRM {
        max-width: 100%;
        overflow: hidden;
    }

    .checklistReadMore {
        display: flex;
        align-items: center;
        /* margin: 0 -21vw -1vw 0; */
        margin: -17px -21vw 1vw 0;
    }

    .checkReadMore {
        width: 10%;
        margin-left: 1vw;
    }

    .checklistReadMore span {
        color: var(--font-color-secondary);
        font-size: 2.2vw;
        line-height: 2.8vw;
        font-weight: bold;
        margin: 0 1vw 0 2.5vw;
    }

    .checklistReadMore {
        max-width: 100%;
        overflow: hidden;
    }

}

@media screen and (min-width:1024px) {

    .textTwoRM {
        font-size: 3vw;
        margin: 0 0 2rem 0;
    }

    .checklistReadMore {
        font-size: 1.11vw;
        margin: 0 11vw 0 .5vw;
        line-height: 1.31vw;
        margin: -21px 0 0 0;
    }

    .checklistReadMore span {
        margin: 0 3vw 0 1.5vw;
    }

}

@media screen and (min-width:1600px) {

    .introContainerRM {
        margin: 28px 0 0 0;
    }

    .introContainerRM h4 {
        font-size: 51px;
        line-height: 55.2px;
        margin: 0 0 -32.5px 0;
    }

    .textContainerRM {
        margin: 34px 0 43px 0;
    }

    .textOneRM {
        font-size: 26.5px;
    }

    .textTwoRM {
        font-size: 45px;
        line-height: 55.2px;
    }

    .titleContainerRM span {
        font-size: 65.5px;
    }

    .titleContainerRM>h3 {
        font-size: 69px;
    }

    .checklistReadMore span {
        font-size: 48px;
        line-height: 49px;
        margin: 0;
    }

    .checklistReadMore {
        padding: 15px 0;
        margin: 70px 140px -60px 0px;
    }
}