.profileImg {
    width: 84%;
    height: auto;
    border: 7px #5A6F8E solid;
}

.scheduleButton {
    width: 90%;
    margin: 4vw 0 10vw 0;
    border: 0;
    border-radius: .4rem;

    background-color: var(--color-primary);

    color: white;
    font-size: 4.2vw;
    letter-spacing: .1vw;
    padding: 3vw 0;
}

.scheduleButton:hover {
    background-color: #215858;
    transition: 0.2s;
}

.logoDiv h3 {
    font-size: 8vw;
    line-height: 6.8vw;
    color: var(--font-color-primary);
    font-family: var(--font-style-primary);
}

.introContainer {
    width: 90%;
    margin: 15px 0 0 0;

    background-color: #ffffffed;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.introContainer h4 {
    color: var(--color-secondary);
    font-size: 4vw;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    margin: 1rem 0 -.5rem 0;
}

.titleContainer {
    margin: 1rem 0 0 0;
    width: 100%;
}

.contentContainer {
    width: 100%;
    padding-bottom: 10vw;

    background-image: url("../../assets/mobile/about/background.jpg");
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleContainer>h3 {
    font-size: 5vw;
    margin: .5rem 0 0 0;
    color: var(--font-color-secondary-light);
    font-family: var(--font-style-primary);
    font-weight: 500;
}

.introContainer>p {
    margin: 2vw 7.5vw;

    text-align: center;
    font-size: 4vw;
    font-style: italic;
    line-height: 5.5vw;
}

.title {
    font-family: var(--font-style-secondary);
    font-size: 2.55vw;
}

.mainContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}


.titleContainer>* {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}


@media screen and (min-width:375px) {
    .introContainer p {
        margin: 2vw 6vw;
        font-size: 4.2vw;
    }
}

@media screen and (min-width:600px) {

    .introContainer p {
        margin: 2vw 7.5vw;
        font-size: 4.4vw;
    }
}

@media screen and (min-width:728px) {

    .profileImg {
        width: 35%;
        margin: 15px 18px 31px 0;
    }

    .scheduleButton {
        font-size: 1.51vw;
        padding: 1.1vw 0;
        width: 62%;
        margin: 1vw 0 0 0;
    }

    .mainContainer {
        width: 100%;
        /* aspect-ratio: 19 / 10; */
        align-items: start;
        display: block;
    }

    .contentContainer {
        width: 100%;
        padding-bottom: 0%;
        background-image: url("../../assets/desktop/about/background.jpg");
        background-size: cover;
    }

    .logoDiv h3 {
        font-size: 4.5vw;
        line-height: 2.8vw;
        margin: 0 0 -10px 0;
        text-align: center;
    }

    .titleContainer {
        background: none;
        position: static;
    }

    .titleContainer h3 {
        font-size: 4.4vw;
        line-height: 4vw;
    }

    .title {
        font-size: 1.11vw;
    }

    .introContainer {
        display: block;
        width: 96%;
        margin: 15px 0 15px 0;
        background: rgb(255, 255, 255);
        background: linear-gradient(250deg, rgba(255, 255, 255, 1) 0%, rgb(255 255 255 / 79%) 25%, rgba(255, 255, 255, 0) 100%, rgba(9, 9, 121, 0) 100%);

        display: flex;
        flex-direction: row
    }

    .introContainer h4 {
        font-size: 2vw;
    }

    .introContainer p {
        font-size: 1.4vw;
        line-height: 2vw;
        margin: 18px 17px 8px 0px;
        text-align: justify;
    }

    .divider {
        padding: .4rem;
        background: rgb(71, 80, 95);
        background: linear-gradient(90deg, rgba(71, 80, 95, 1) 0%, rgba(89, 109, 139, 1) 46%, rgba(71, 80, 95, 1) 100%, rgba(9, 9, 121, 0) 100%, rgba(0, 212, 255, 1) 100%);
        width: 100%;
        height: 1px;
    }

}

@media screen and (min-width: 1485px) {

    .scheduleButton {
        font-size: 22.51px;
        padding: 11.1px 0;
        width: 52%;
        margin: 17px 0 0 0;
    }

    .titleContainer h3 {
        font-size: 61.4px;
        line-height: 54px;
    }

    .title {
        font-size: 15.11px;
    }

    .logoDiv {
        margin: 1rem 0 0 0;
    }

    .logoDiv h3 {
        font-size: 65.5px;
        line-height: 51px;
    }

    .introContainer p {
        font-size: 20.4px;
        line-height: 28.8px;
        margin: 31px 61px 6px 77px;
    }

    .introContainer h4 {
        font-size: 34px;
    }

}