.checklistDesktop {
    display: flex;
    align-items: center;
    padding: 2vw 0;
    /* margin: 7px 0 -2vw 0; */
    /* background: rgba(255, 255, 255, 0.7); */
}

.checkDesktop {
    width: 15%;
    margin-left: 5vw;
}

.checklistDesktop span {
    color: var(--font-color-secondary);
    font-size: 2.7vw;
    font-weight: bold;
    margin: 0 0 0 5vw;
}


@media screen and (min-width:600px) {

    .checklistDesktop span {
        font-size: 2.5vw;
    }

    .checkDesktop {
        margin-left: 2vw;
    }

}

@media screen and (min-width:728px) {

    .checklistDesktop {
        display: flex;
        align-items: center;
        /* margin: 0 -21vw -1vw 0; */
        margin: -25px -21vw 1vw 0;
    }

    .checkDesktop {
        width: 10%;
        margin-left: 1vw;
    }

    .checklistDesktop span {
        color: var(--font-color-secondary);
        font-size: 2.2vw;
        line-height: 2.8vw;
        font-weight: bold;
        margin: 0 1vw 0 2.5vw;
        text-transform: capitalize;
    }

    .checklistDesktop {
        max-width: 100%;
        overflow: hidden;
    }

}

@media screen and (min-width:1024px) {
    .checklistDesktop {
        font-size: 1.11vw;
        margin: 0 11vw 0 .5vw;
        line-height: 1.31vw;
        margin: -21px 0 0 0;
    }

    .checklistDesktop span {
        margin: 0 -2vw 0 1.5vw;
        font-size: 1.4vw;
        line-height: 1.8vw;
        font-weight: 500;
    }

    .checklistDesktop {
        margin: -28px 0 0 0;
    }

}

@media screen and (min-width:1600px) {

    .checkDesktop {
        margin-left: 79px;
    }

    .checklistDesktop span {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
    }

    .checklistDesktop {
        padding: 15px 0;
        /* margin: 70px 140px -60px 0px; */
    }
}