@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=EB+Garamond:ital;wght@0;400;0;500;0;600;0;700;1;400&family=Merriweather:wght@400;700&family=Montserrat:ital;wght@0;100;0;300;0;500;1;100&family=Roboto:wght@100;300;400;500;700&display=swap');

.footerDesktop {
    padding: .3rem;
    background-color: #2b5e86;
    width: 100%;
    color: white;
    font-size: .8rem;
    text-align: center;
}



@media screen and (min-width:600px) {

    .divWrapper h6 {
        font-size: 3vw;
    }

    .brDisplay {
        display: none;
    }
}

@media screen and (min-width:768px) {

    .divWrapper h6 {
        font-size: 2.5vw;
    }

    .brDisplay {
        display: none;
    }
}

@media screen and (min-width:1024px) {

    .divWrapper h6 {
        font-size: 20px;
    }

    .brDisplay {
        display: none;
    }
}