.slideshow {}

.slidesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.slidesContainer {
    margin: 6vw 0;
}

.carouselItem {
    padding: 0 1vw;
}

.btn {
    width: 100%;
    /* margin: 4vw 0 10vw 0; */
    border: 0;
    border-radius: .4rem;
    background-color: var(--color-primary);
    color: white;
    font-size: 4.2vw;
    letter-spacing: .1vw;
    padding: 4vw 0 4vw 0;
}

.btn:hover {
    background-color: #215858;
    transition: 0.2s;
}

@media screen and (min-width:728px) {}

@media screen and (min-width:1600px) {
    .slidesContainer> :first-child {
        margin-bottom: calc(1600px * 0.06);
    }

    .carouselItem {
        padding: 0 calc(1600px * 0.01);
    }
}